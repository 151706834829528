// React Basic and Bootstrap
import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Button,
  Label,
  Card,
  CardBody,
} from 'reactstrap';
// import { Link } from 'react-router-dom';
// import FeatherIcon from 'feather-icons-react';

//Import Components
// import SectionTitle from '../../components/Shared/SectionTitle';

// import mobileNotificationSVG from '../../assets/images/illustrator/Mobile_notification_SVG.svg';

// Import Images
// import seoSVG from '../../assets/images/illustrator/SEO_SVG.svg';
import VisionImg from '../../assets/images/SaiBrindavanKids/bg01.jpg';
import MissionImg from '../../assets/images/SaiBrindavanKids/bg03.png';

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [
        { title: 'Digital Marketing Solutions for Tomorrow1' },
        { title: 'Our Talented & Experienced Marketing Agency' },
        { title: 'Create your own skin to match your brand' },
      ],
      features1: [
        { title: 'Digital Marketing Solutions for Tomorrow11' },
        { title: 'Our Talented & Experienced Marketing Agency' },
        { title: 'Create your own skin to match your brand' },
      ],
      Contactvisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section ">
          <Container>
            <div className="text-center">
              <h2> Why Sai Brindavan Kids?</h2>
              <p>
                Children at every stage of their growth require different
                developmental needs and it is every parents and teachers
                responsibility to cater to the right developmental need and
                approach at the right time.
              </p>
            </div>

            <Row className="align-items-center">
              <Col lg="5" md="6" className="mt-4 pt-2">
                <img
                  src={VisionImg}
                  alt=""
                  style={{ height: '500px', width: '485px' }}
                />
              </Col>

              <Col lg="7" md="6" className="mt-4 pt-2">
                <div className="section-title ms-lg-5">
                  <h4 className="title mb-4">Our Vision</h4>
                  <p className="text text-align-justify">
                    Our vision at Sai Brindavan Kids is to empower young minds
                    with a holistic educational experience that fosters
                    creativity, critical thinking, and lifelong learning.
                  </p>
                  <ul className="list-unstyled text">
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      We aspire to create a nurturing environment where every
                      child thrives academically, socially, and emotionally,
                      setting the foundation for their future success.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      We envision being a pioneer in innovative learning
                      methods, incorporating Montessori principles and modern
                      educational approaches to inspire curiosity and love for
                      learning.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Our vision includes actively engaging with parents,
                      educators, and the community to create a supportive
                      network that encourages the holistic development of each
                      child.
                    </li>
                  </ul>
                  {/* <Link to="#" className="mt-3 h6 text-primary">
                    Find Out More{' '}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link> */}
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col
                lg="7"
                md={{ size: 6, order: 1 }}
                xs={{ order: 2 }}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0"
              >
                <div className="section-title">
                  <h4 className="title mb-4">Our Mission</h4>
                  <p className="text text-align-justify">
                    Our mission is to provide a comprehensive preschool
                    education that focuses on the holistic development of
                    children, including cognitive, social, emotional, and
                    physical growth.
                  </p>
                  <ul className="list-unstyled text">
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>{' '}
                      We are committed to offering enriching after-school
                      activities that complement classroom learning, providing
                      opportunities for exploration, creativity, and personal
                      growth.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      We prioritize creating a safe, inclusive, and supportive
                      environment where every child feels valued, respected, and
                      encouraged to reach their full potential academically and
                      personally.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      We are dedicated to enhancing literacy and language skills
                      through specialized programs such as Jolly Phonics,
                      Syllabification, and Jolly Grammar, providing personalized
                      one-to-one classes both online and offline.
                    </li>
                  </ul>
                  {/* <Link to="#" className="mt-3 h6 text-primary">
                    Find Out More{' '}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link> */}
                </div>
              </Col>

              <Col lg={5} md={6} className="order-1 order-md-2">
                <img
                  src={MissionImg}
                  alt=""
                  style={{ height: '500px', width: '485px' }}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default HowItWorks;
