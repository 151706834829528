// React Basic and Bootstrap
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Popup from '../../components/Layout/popup';

// Feature Images
import saas2 from '../../assets/images/SaiBrindavanKids/founder.jpg';

// import Section from './Home';
// import { agencyFeatureArray } from '../../common/data';

const IndexAgency = () => {
  return (
    <React.Fragment>
      {/* Feature */}
      <section className="section">
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <div className="section-title ms-lg-3 text-center">
              <h4 className="title mb-4">About Us</h4>
            </div>
            <Col lg={6} md={6}>
              <img src={saas2} className="img-fluid" alt="" />
            </Col>

            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-3">
                {/* <h4 className="title mb-4">About Us</h4> */}
                <h5>K. Ranjitha Anil Kumar</h5>
                <p>Founder & Director, Sai Brindavan Kids</p>
                <p className="text" style={{ textAlign: 'justify' }}>
                  • As the founder and director of Sai Brindavan Kids, I bring a
                  profound passion for education and a steadfast commitment to
                  empowering children with the best educational services. With a
                  vision to fill the gap in educational offerings for children
                  who may not have access to services provided by branded
                  schools, I embarked on a journey to establish a nurturing
                  learning environment that prioritizes holistic development and
                  individual growth. <p></p>• Driven by the belief that every
                  child deserves quality education tailored to their unique
                  needs, I have dedicated myself to creating an inclusive space
                  where children from all backgrounds can thrive academically,
                  socially, and emotionally. Through Sai Brindavan Kids, I aim
                  to redefine the educational landscape by offering
                  comprehensive preschool education, enriching after-school
                  activities, and specialized tutorials in Math and Science for
                  students in Grade 8th to Grade 10th, accommodating various
                  curriculum standards including ICSE, CBSE, and State boards.
                  <p></p>• My commitment extends beyond academic excellence to
                  encompass the development of essential life skills, cultural
                  awareness, and a sense of global citizenship. I believe in
                  fostering a collaborative community where parents, educators,
                  and stakeholders actively participate in shaping the
                  educational journey of each child.
                  {/*<p></p>• With a deep-rooted
                  belief in the transformative power of education, I strive to
                  make a meaningful impact on the lives of children, equipping
                  them with the tools and mindset necessary to navigate an
                  ever-changing world with confidence and resilience. At Sai
                  Brindavan Kids, we are not just shaping futures; we are
                  nurturing the leaders, innovators, and compassionate
                  individuals of tomorrow.<p></p>• Through continuous
                  innovation, personalized attention, and a steadfast dedication
                  to our core values, I am committed to leading Sai Brindavan
                  Kids towards excellence in education, ensuring that every
                  child who walks through our doors receives the best possible
                  foundation for lifelong success.<p></p> */}
                </p>
                {/* <Link to="#" className="mt-3 h6 text-primary">
                  Find Out More{' '}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default IndexAgency;
