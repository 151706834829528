import React, { useState, useEffect } from 'react';

import Home from './Home';
import Gallery from './Gallery';
import Cta from './Cta';
import Services from './Services';
import Testimonials from './Testimonials';
import Contact from './Contact';
import Curriculam from './Curriculam';
import AboutUs from './AboutUs';
import Classroom from './Classroom';
import Navbar from './NavbarPage';
import VisionMission from './VisionMission';
import PopularDestination from './PopularDestination';

const Index = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    document.body.classList = '';
    document.getElementById('topnav').classList.add('bg-white');

    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  return (
    <React.Fragment>
      <Navbar />

      {isMobileView ? (
        <div id="home">
          <PopularDestination />
        </div>
      ) : (
        <div id="home">
          <Home />
        </div>
      )}

      <div id="aboutus">
        <AboutUs />
      </div>

      <div id="visionmission">
        <VisionMission />
      </div>

      <div id="classroom">
        <Classroom />
      </div>

      <div id="gallery">
        <Gallery />
      </div>

      {/* Cta */}
      <Cta />

      <div id="curriculam">
        <Curriculam />
      </div>

      {/* Services */}
      <Services />

      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M720 125L2160 0H2880V250H0V125H720Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <Testimonials />

      <div id="contact">
        <Contact />
      </div>

      {/* <Popup /> */}
    </React.Fragment>
  );
};

export default Index;
