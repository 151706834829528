// React Basic and Bootstrap
import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Components
import SectionTitle from '../../components/Shared/SectionTitle';
//import { hotelRoomsData } from "../../common/data";

// Import Images
import Img1 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-32.jpg';
import Img3 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-33.jpg';
import Img4 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-34.jpg';
import Img6 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-35.jpg';
import Img7 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-36.jpg';
import Img8 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-37.jpg';
// import Img11 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-38.jpg';
// import Img12 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-39.jpg';
// import Img13 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-40.jpg';
// import Img15 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-41.jpg';
// import Img16 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-42.jpg';
// import Img17 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-43.jpg';
// import Img18 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-69.jpg';
// import Img19 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-45.jpg';
// import Img21 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-47.jpg';
// import Img22 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-48.jpg';
// import Img23 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-49.jpg';
// import Img25 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-50.jpg';
// import Img26 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-51.jpg';
// import Img27 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-52.jpg';
// import Img28 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-53.jpg';
// import Img29 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-54.jpg';
// import Img30 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-66.jpg';
// import Img31 from '../../assets/images/SaiBrindavanKids/GalleryImages/IMG-55.jpg';
// import { useState } from 'react/cjs/react.production.min';

const Images = [Img1, Img3, Img4, Img6, Img7, Img8];

// const Images1 = [
//   Img11,
//   Img13,
//   Img15,
//   Img16,
//   Img28,
//   Img30,
//   Img19,
//   Img21,
//   Img18,
//   Img12,
//   Img31,
//   Img22,
//   Img23,
//   Img25,
//   Img26,
//   Img27,
//   Img17,
//   Img29,
// ];

const Gallery = () => {
  const [readMore, setReadMore] = useState(false);
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* section title */}
          <SectionTitle title="Gallery" />

          <Row>
            {Images.map((img, key) => (
              <Col lg="4" md="6" key={key} className="mt-4 pt-2">
                <Card className="work-container work-modern rounded border-0 overflow-hidden">
                  <CardBody className="p-0">
                    <img src={img} className="img-fluid rounded" alt="work" />
                    <div className="overlay-work"></div>
                  </CardBody>
                </Card>
              </Col>
            ))}
            {/* {readMore &&
              Images1.map((img, key) => (
                <Col lg="4" md="6" key={key} className="mt-4 pt-2">
                  <Card className="work-container work-modern rounded border-0 overflow-hidden">
                    <CardBody className="p-0">
                      <img src={img} className="img-fluid rounded" alt="work" />
                      <div className="overlay-work"></div>
                    </CardBody>
                  </Card>
                </Col>
              ))} */}
          </Row>

          <Row className="justify-content-center">
            <Col xs="12" className="text-center mt-4 pt-2">
              {/* {!readMore ? (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setReadMore(true);
                  }}
                >
                  View More
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => setReadMore(false)}
                >
                  View Less
                </button>
              )} */}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Gallery;
