import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
// import images
// import blog1 from '../../assets/images/blog/01.jpg';
// import { object } from 'yup';
//import { useState } from 'react/cjs/react.production.min';
import Img1 from '../../assets/images/SaiBrindavanKids/Classroom/IMG_1.jpg';
import Img2 from '../../assets/images/SaiBrindavanKids/Classroom/IMG_2.jpg';
import Img3 from '../../assets/images/SaiBrindavanKids/Classroom/IMG_3.jpg';
import Img4 from '../../assets/images/SaiBrindavanKids/Classroom/IMG_4.jpg';
import Img5 from '../../assets/images/SaiBrindavanKids/Classroom/IMG_6.jpg';
const Cards = () => {
  const [business, setbusiness] = useState([
    {
      id: 0,
      image: Img1,
      title: '(2 to 15 years) - Daycare',
      isOpen: false,
      description:
        'A daycare class is a nurturing and educational environment designed for.',
      description1:
        'A daycare class is a nurturing and educational environment designed for the care of young children,In these settings, trained caregivers provide a safe and engaging space where children can learn, play, and socialize with their peers. Daycare classes often follow structured routines that include age-appropriate activities such as storytime, arts and crafts, music, outdoor play, and educational games. The focus is on fostering social, emotional, cognitive, and physical development in a supportive and stimulating atmosphere. Parents rely on daycare classes to provide a balance of care, learning, and fun for their children while they attend work or other commitments.',
    },
    {
      id: 1,
      image: Img2,
      title: '(2 to 3 years) - Play Group',
      isOpen: false,
      description:
        'Social, Emotional, Language and Physical development is the prior focus.',
      description1:
        'Social, Emotional, Language and Physical development is the prior focus. As the child starts to get inquisitive about the visual content in his/her surrounding, excited about the sounds heard, and when the development of his/her kinetic energy starts to build stronger, it is important for us to trigger and help develop the basic personality of a child. While still developing the concept of sharing, children in this age group begin to understand the basics of taking turns and sharing toys with peers and exploring their environment.',
    },
    {
      id: 2,
      image: Img3,
      title: '(3 to 4 years) - Nursery',
      isOpen: false,
      description:
        'Motor, Cognitive, Language and Numeracy development is the prior.',
      description1:
        'Motor, Cognitive, Language and Numeracy development is the prior focus. As the child gradually has developed on the SELP aspect of understanding colors, visual presence of mind and emotional expressions, the energy then for the child is to kickstart his motor skills to help reach to whatever he is attracted. Then on the cognitive skills help the child to think, understand and memorize what he sees, reads or listens. By now the child starts to master many languages he is taught with. To top it all the child starts to develop numeracy skills.',
    },
    {
      id: 3,
      image: Img4,
      title: '(4 to 5 years) - LKG',
      isOpen: false,
      description:
        'As the child is guided with the right developmental modules in mastering his',
      description1:
        "As the child is guided with the right developmental modules in mastering his social, emotional, language, physical, motor, cognitive and numeracy skills, Literacy and communication becomes the icing on the cake for a right personality to be molded. Literacy will help the child develop into a sensible, responsible and academically sound individual while communication helps the parent, teacher's and his clan to understand the kind of human being he/she is and the thought process which the child values.",
    },
    {
      id: 4,
      image: Img5,
      title: '(5 to 6 years) - UKG',
      isOpen: false,
      description:
        "UKG class is an important stage in a child's early education, typically",
      description1:
        "UKG class is an important stage in a child's early education, typically catering to children aged 4 to 5 years old. In this preparatory year before formal schooling begins, UKG focuses on building foundational skills in numeracy, literacy, and social development. Children engage in various activities to enhance their cognitive abilities, language skills, and fine motor skills through play-based learning. UKG curriculum often includes introductory reading and writing exercises, basic math concepts, art, music, and physical activities. It aims to prepare children for the transition to primary school by fostering independence, curiosity, and a love for learning.",
    },
  ]);
  return (
    <React.Fragment>
      <section className="section" id="ui-card">
        <Container>
          <Row>
            <Col lg={12} md={8} className="col-12 ">
              <Row>
                <div className="text-center">
                  <h2>Classroom</h2>
                  {/* <p>
                    Children at every stage of their growth require different
                    developmental needs and it is every parents and teachers
                    responsibility to cater to the right developmental need and
                    approach at the right time.
                  </p> */}
                </div>
                <Col>
                  <div className="component-wrapper rounded shadow">
                    <div className="p-4 ">
                      <Row>
                        {business.map((data, id) => (
                          <Col lg={4} className="col-12 mt-4 pt-2" key={id}>
                            <Card className="shadow rounded border-0 overflow-hidden">
                              <img
                                src={data.image}
                                className="img-fluid"
                                alt=""
                              />
                              <CardBody>
                                <h5 className="card-title">{data.title}</h5>
                                {data.isOpen == true
                                  ? data.description1
                                  : data.description}
                                {data.isOpen ? (
                                  <Label
                                    className="btn text-primary"
                                    onClick={() => {
                                      let l = [...business];
                                      l[data.id].isOpen = false;
                                      setbusiness(l);
                                    }}
                                  >
                                    Read Less
                                  </Label>
                                ) : (
                                  <Label
                                    className="btn text-primary"
                                    onClick={() => {
                                      let l = [...business];
                                      l[data.id].isOpen = true;
                                      setbusiness(l);
                                    }}
                                  >
                                    Read More
                                  </Label>
                                )}
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Cards;
