// React Basic and Bootstrap
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

//Import Components
import SectionTitle from '../../components/Shared/SectionTitle';
// import { hotelFeaturesData } from "../../common/data";

const hotelFeaturesData = [
  {
    id: 1,
    icon: 'uil uil-webcam h2 text-primary',
    title: 'CCTV',
    description: '24/7 CCTV Surveillance.',
  },
  {
    id: 2,
    icon: 'uil uil-process h2 text-primary',
    title: 'Classrooms',
    description: 'Audio Visual illustration Rooms.',
  },
  {
    id: 3,
    icon: 'uil uil-medkit h2 text-primary',
    title: 'Medical Care',
    description: 'Emergency Medical First Aid Care Unit.',
  },
  {
    id: 4,
    icon: 'uil uil-shield-check h2 text-primary',
    title: 'Safety',
    description:
      'Child friendly with child safe protocols ensured and followed.',
  },
  {
    id: 5,
    icon: 'uil uil-th-large h2 text-primary',
    title: 'Play area',
    description:
      'Hygienic and Spacious play area & multipurpose studio for dance, physical activities etc.',
  },
  {
    id: 6,
    icon: 'uil uil-bed-double h2 text-primary',
    title: 'Daycare',
    description:
      'Child friendly environment including meals, naps, and educational playtime. ',
  },
];

const Services = () => {
  return (
    <React.Fragment>
      <section className="section">
        <Container className="pb-lg-4 mb-md-5 mb-4">
          {/* section title */}
          <SectionTitle
            title="The Environment"
            // desc=" that can provide everything you need to generate awareness, drive traffic, connect"
          />

          {/* feature box */}
          <Row>
            {hotelFeaturesData.map((item, key) => (
              <Col key={key} md={4} className="col-12 mt-5">
                <div className="features feature-primary text-center">
                  <div className="image position-relative d-inline-block">
                    <i className={item.icon}></i>
                  </div>
                  <div className="content mt-4">
                    <h5>{item.title}</h5>
                    <p className="text-muted mb-0">{item.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
