import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';

//Star Rating
import StarRatings from 'react-star-ratings';
import ProfilePic from '../../assets/images/SaiBrindavanKids/profileImage.jpg';

class ReviewSlider4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.reviews = [
      {
        id: 1,
        image: ProfilePic,
        name: 'Girija Kumbara',
        rating: 5,
        desc: "Such a great environment for youngsters to feel at ease.Mothers do not need to be concerned about their children's education everything will be handled flawlessly. You may always relax knowing that your children are in capable and caring hands. Thank you so much, Sai Brindavan.",
      },

      {
        id: 2,
        image: ProfilePic,
        name: 'Vinutha P',
        rating: 5,
        desc: "Ranjitha, a very kind and friendly person, that's why kids get attached to her and attracted to her. She also has the knowledge and skills required to deal with and teach the children in a playful way. All kids coming out of her school are confident and independent.",
      },
      {
        id: 3,
        image: ProfilePic,
        name: 'Ashu Deep',
        rating: 5,
        desc: '1st place thank you for your time and patience. Your interest in all the kids activities given for their better growth, better prospects and better opportunities. Wonderful dedication 💓 from your end. We all wish you the same love and amazing efforts with all your contribution given for our kids. Thank you. Mam in simple words you are out of the box for our children future. We all love you 💗 Keep going..',
      },
      {
        id: 4,
        image: ProfilePic,
        name: 'Rashmi puttappa naik',
        rating: 5,
        desc: "Teachers are too good . They know that each child is different and handle them accordingly. My daughter was not able to learn phonics in school but Ranjita Mam helped my daughter to learn phonics. Also she has learnt to write cursive letter writing . I appreciate the amount of dedication they put in teaching kids.. teaching is not a job for them but PASSION. Special thanks to Ranjita Ma'am.",
      },
      {
        id: 5,
        image: ProfilePic,
        name: 'Shweta Gowda',
        rating: 5,
        desc: 'I feel happy that my child is learning many things from Sai brindavan kids.i thank all the teachers of Sai brindavan.for there support on children and parents.',
      },
      {
        id: 6,
        image: ProfilePic,
        name: 'Hrishikesh Talampally',
        rating: 5,
        desc: 'Teachers are well qualified....Students get good and attractive environment... Teachers here teach professional phoenix and activities, teachers make students habituate to the environment.... Totally a great pack of education is given by SBK.',
      },
    ];
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.clients.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.clients.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    return (
      <React.Fragment>
        <Carousel
          activeIndex={this.state.activeIndex}
          next={this.next}
          previous={this.previous}
        >
          {this.reviews.map((client, key) => (
            <CarouselItem
              key={key + 1}
              onExiting={this.onExiting()}
              onExited={this.onExited()}
            >
              <div key={key} className="customer-testi text-center">
                <p className="text-light para-dark h6 fst-italic mb-2">
                  " {client.desc} "
                </p>
                <StarRatings
                  rating={client.rating}
                  starRatedColor="#F17425"
                  numberOfStars={5}
                  name="rating"
                  starDimension="15px"
                  className="list-unstyled mb-0 mt-3"
                  starSpacing="1px"
                />
                <h6 className="text-light title-dark mt-1">
                  {client.name}
                  <small className="text-muted ms-1">{client.post}</small>
                </h6>
                <img
                  src={client.image}
                  style={{ height: 65, width: 65 }}
                  className="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                  alt=""
                />
              </div>
            </CarouselItem>
          ))}
          <CarouselControl direction="prev" onClickHandler={this.previous}>
            <span className="carousel-control-prev-icon"></span>
            <span className="sr-only">Previous</span>
          </CarouselControl>
          <CarouselControl direction="next" onClickHandler={this.next}>
            <span className="carousel-control-next-icon"></span>
            <span className="sr-only">Next</span>
          </CarouselControl>
        </Carousel>
      </React.Fragment>
    );
  }
}

export default ReviewSlider4;
